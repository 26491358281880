import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { AiOutlineLogout } from 'react-icons/ai';
import { GoSearch } from 'react-icons/go';
import { CgRing } from 'react-icons/cg';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';
import { setTypeProduitCatalogue, setFormePierre, setSearchProduitCatalogue, setLigneProduitCatalogue, setAuthentification } from '../actions'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { imagesRepo } from '../config';



const Navbar = ({ setTypeProduitCatalogue, search, setFormePierre, setSearchProduitCatalogue, setLigneProduitCatalogue, typeProduit, ligne, forme }) => {
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    const updateUserFavicon = () => {
      const logo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))[0].logo : 'logo.png';
      const faviconSrc = `${imagesRepo}logo/${logo}`;
      updateFavicon(faviconSrc);
    };

    updateUserFavicon();
  }, []);

  const updateFavicon = (faviconSrc) => {
    let favicon = document.querySelector('link[rel="icon"]');
    if (!favicon) {
      const newFavicon = document.createElement('link');
      newFavicon.rel = 'icon';
      document.head.appendChild(newFavicon);
      favicon = newFavicon;
    }
    // Déterminer le type de l'image en fonction de son extension
    const imageType = faviconSrc.endsWith('.png') ? 'image/png' : faviconSrc.endsWith('.jpg') ? 'image/jpeg' : '';
    favicon.type = imageType;
    favicon.href = faviconSrc;
  };


  const changeType = (type) => {
    setTypeProduitCatalogue(type)
    setSearchProduitCatalogue('')
    setLigneProduitCatalogue('')
    setFormePierre('')
    setSidebar(false)
  }
  const handleSearch = e => {
    if (e) {
      setSearchProduitCatalogue(e.target.value)
      setTypeProduitCatalogue('')
      setLigneProduitCatalogue('')
      setFormePierre('')

    }
  }
  const handleSelectLigne = e => {
    if (e) {
      setLigneProduitCatalogue(e.target.value)
      setTypeProduitCatalogue('')
      setSearchProduitCatalogue('')
      setFormePierre('')

      //setSidebar(false)
    }
  }
  const handleSelectForme = e => {
    if (e) {
      setFormePierre(e.target.value)
      setTypeProduitCatalogue('')
      setSearchProduitCatalogue('')
      setLigneProduitCatalogue('')
      //setSidebar(false)
    }
  }
  const reset = () => {
    setTypeProduitCatalogue('')
    setSearchProduitCatalogue('')
    setLigneProduitCatalogue('')
    setFormePierre('')

    setSidebar(false)
  }

  const deconnection = () => {
    localStorage.removeItem('user');

  }
  const labelTypeUser = () => {
    if (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))[0].admin === '1') return 'Admin'
    else return 'Boutique'
  }
  // const returnLogo = () => {
  //   const logo = JSON.parse(localStorage.getItem('user'))[0].logo
  //   console.log(logo);
  //   return `${imagesRepo}logo/${logo}`
  // }
  return (
    <>
      <IconContext.Provider value={{ color: '#000' }}>
        <div className='navbar' onMouseEnter={() => setSidebar(true)}>
          <div href='#' className='menu-bars'>
            <FaBars onClick={() => setSidebar(true)} />
          </div>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'} onMouseLeave={() => setSidebar(false)}>
          <ul className='nav-menu-items'>
            <li className=''>
              <div href='#' className='menu-bars'>
                <FaBars onClick={() => setSidebar(false)} />
              </div>
              <img style={{ height: 50 }} src={localStorage.getItem('user') ? imagesRepo + 'logo/' + JSON.parse(localStorage.getItem('user'))[0].logo : imagesRepo + 'logo/logo.png'} alt="" className="img-fluid" />
              <h6>{labelTypeUser()}</h6>

            </li>
            <li className='nav-text'>
              <div className="iconType">
                <AiOutlineLogout />
              </div>
              <Link to="/login" onClick={deconnection} className="linkBtn">Déconnection</Link>
            </li>


            <li className='nav-text'>
              <div className="iconType">
                <CgRing />
              </div>
              <span>
                <button onClick={reset} className="linkBtn">  Tous nos Produits</button>
              </span>
            </li>

            <li className='nav-text'>
              <div className="iconType">
                <GoSearch />
              </div>
              <span>
                <input value={search} onChange={handleSearch} type='text' className="inputCard" />
              </span>
            </li>

            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName} style={{ backgroundColor: typeProduit === item.id ? '#afcbed' : '' }}>
                  <div className="iconType">
                    {item.icon}
                  </div>
                  <span>
                    <button onClick={() => changeType(item.id)} className="linkBtn">  {item.title}</button>
                  </span>
                </li>
              );
            })}

            <li className='nav-text'>
              <div className="iconType">
                <GoSearch />
              </div>
              <span>
                <select value={forme} onChange={handleSelectForme} className="form-select selectCard">
                  <option value="">Pierres</option>
                  {/* <option value="Classique">Classique</option> */}
                  <option value="1005">Rond</option>
                  <option value="1006">Ovale</option>
                  <option value="1008">Princesse</option>
                  <option value="1016">Poire</option>
                  <option value="1035">Coussin</option>
                  <option value="1012">Taille Emeraude</option>
                </select>
              </span>
            </li>

            <li className='nav-text'>
              <div className="iconType">
                <GoSearch />
              </div>
              <span>
                <select value={ligne} onChange={handleSelectLigne} className="form-select selectCard">
                  <option value="">Lignes</option>
                  {/* <option value="Classique">Classique</option> */}
                  <option value="Vintage">Vintage</option>
                  <option value="Moderne">Moderne</option>
                  <option value="Tendance">Tendance</option>
                  <option value="Elegance">Elégance</option>
                  <option value="Glamour">Glamour</option>
                  <option value="Extensia">Extensia</option>
                </select>
              </span>
            </li>

          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    search: state.catalogue.search,
    typeProduit: state.catalogue.typeProduit,
    ligne: state.catalogue.ligne,
    forme: state.catalogue.forme,
  }
}
const mapDispatchToProps = {
  setTypeProduitCatalogue,
  setSearchProduitCatalogue,
  setLigneProduitCatalogue,
  setFormePierre,
  setAuthentification,
}
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
