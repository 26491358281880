import React, { useEffect, useState } from 'react'
import { setAuthentification, setLogin } from '../actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const Login = ({ setAuthentification, isLoggedIn, history, setLogin, user }) => {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [err, setErr] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('user')) {
            setAuthentification(true);
            history.push("/");
        }
    }, [history, setAuthentification]);

    const login = e => {
        e.preventDefault()
        setErr(false)
        setLogin(userName, password).then((response) => {
            if (response) {
                setAuthentification(true)
                history.push("/");

            }
            else {
                setErr(true)
            }
        })
        // setAuthentification(true)
        // history.push("/gallery");
    }
    return (
        // <div className="catalogue ">
        //     {isLoggedIn}
        //     <input type='text' />
        //     <input type='text' />
        //     <button onClick={login}>Login </button>
        // </div>
        <div className="container">
            <div className="login-form-1">
                <h3>Identification</h3>
                <form onSubmit={login}>
                    <div className="form-group">
                        <input type="text" className="inputLog" placeholder="Utilisateur" onChange={e => {
                            setUserName(e.target.value)
                            setErr(false)
                        }} value={userName} />
                    </div>
                    <div className="form-group">
                        <input type="password" className="inputLog" placeholder="Mot de passe" onChange={e => {
                            setPassword(e.target.value)
                            setErr(false)
                        }} value={password} />
                    </div>
                    {(user === -1 && err) && <h6>Utilisateur ou mot de passe invalide</h6>}
                    <div className="form-group">
                        <input type="submit" className="btnSubmit" value="Connexion" />
                    </div>
                </form>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authentification.isLoggedIn,
        user: state.authentification.user
    }
}
const mapDispatchToProps = {
    setAuthentification,
    setLogin
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))