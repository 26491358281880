import { GET_PRODUIT_CATALOGUE, SET_FORME_PIERRE, SET_LIGNE_PRODUIT_CATALOGUE, SET_SEARCH_PRODUIT_CATALOGUE, SET_TYPE_PRODUIT_CATALOGUE } from '../actions/action-types';

const initialState = {
    produitCatalogue: [],
    typeProduit: '',
    search: '',
    ligne: '',
    forme: '',
}


export default function ClientReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PRODUIT_CATALOGUE:
            return {
                ...state,
                produitCatalogue: action.payload,
            }
        case SET_TYPE_PRODUIT_CATALOGUE:
            return {
                ...state,
                typeProduit: action.payload,
            }
        case SET_SEARCH_PRODUIT_CATALOGUE:
            return {
                ...state,
                search: action.payload,
            }
        case SET_LIGNE_PRODUIT_CATALOGUE:
            return {
                ...state,
                ligne: action.payload,
            }
        case SET_FORME_PIERRE:
            return {
                ...state,
                forme: action.payload,
            }
        default:
            return state;

    }

}
