import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CardProduct from '../components/CardProduct'
import { Modal, ModalTransition, useModal } from 'react-simple-hook-modal';
import 'react-simple-hook-modal/dist/styles.css';
import { imagesRepo, meekroDB } from '../config'
import axios from 'axios';
import ModalProduit from './ModalProduit';



const DetailProduit = ({ prod, produitCatalogue }) => {

    const [img, setImg] = useState('')
    const { isModalOpen, openModal, closeModal } = useModal();

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('user'))[0].Num_agence === "1") {
            return;
        }
        try {
            const data = {
                table: "log_produit",
                ref: prod,
                id_user: JSON.parse(localStorage.getItem('user'))[0].id_user,
            };

            axios.post(`${meekroDB}/general.php`, data)
                .then(response => {
                    // Gérer la réponse si nécessaire
                })
                .catch(error => {
                    console.log(error);
                });

        } catch (error) {
            console.log("Une erreur s'est produite lors de la tentative d'envoi des données :", error);
        }
    }, [prod]);

    const showImage = prod => {
        setImg(prod.img)
        openModal()
    }
    return (
        < >
            {/* <Modal modalClassName="modalZoom" id="2" isOpen={isModalOpen} transition={ModalTransition.NONE} onBackdropClick={closeModal}>
                <img style={{ width: '600px', height: '600px' }} src={imagesRepo + img} onClick={() => closeModal()} alt="" />
            </Modal> */}
            <ModalProduit isOpen={isModalOpen} closeModal={closeModal} img={img} imagesRepo={imagesRepo} />
            <div className="detailProd">
                {produitCatalogue.filter(f => f.ref === prod).map(p => <CardProduct css={"cardDetail"} detail={showImage} key={p.ref + ' ' + p.label} prod={p} image={p.img} />)}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        produitCatalogue: state.catalogue.produitCatalogue,
    }
}
const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(DetailProduit)