let meekroDB, apiATC;

if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    meekroDB = 'http://127.0.0.1/services/services';
    apiATC = 'http://localhost/api';

} else {
    meekroDB = 'https://www.extensia-france.com/services/services';
    apiATC = 'https://www.extensia-france.com/api';
}
export { meekroDB, apiATC };

export const imagesRepo = 'https://extensia-france.com/imgs/';
