
export const getUniqueProduct = state => {
    let tab = []
    for (let i = 0; i < state.catalogue.produitCatalogue.length; i++) {
        if (i === 0 || state.catalogue.produitCatalogue[i].ref !== state.catalogue.produitCatalogue[i - 1].ref) {
            tab.push(state.catalogue.produitCatalogue[i])
        }
    }
    return tab
    // state.catalogue.produitCatalogue
    //     .reduce((groupedData, r) => {

    //         if (!groupedData) {
    //             console.log("groupedData", groupedData);
    //             console.log('r', r);
    //             // groupedData = []
    //             // groupedData = [...groupedData, r]
    //         }
    //         else if (groupedData.ref !== r.ref) {
    //             console.log("groupedData", groupedData);
    //             console.log('r', r);
    //         }
    //         return r
    //     }, {})
}
