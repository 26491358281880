import { Route, Switch, withRouter } from 'react-router';
import { ModalProvider } from 'react-simple-hook-modal';
import Login from './components/Login';
import Navbar from './components/Navbar'
import ProductGallery from './containers/ProductGallery';
import requireAuthentification from './helpers/require-authentification';

function Home() {
  return (
    <div className="containair-fluid">
      < ModalProvider >
        <Navbar />
        <ProductGallery />
      </ModalProvider>
    </div>
  );
}
const App = () => (

  <Switch>
    <Route exact path="/login" component={Login} />
    <Route path="/" component={requireAuthentification(Home)} />

  </Switch>
);
export default withRouter(App);
