import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import CardProduct from '../components/CardProduct';
import { getProduitCatalogue } from '../actions'
import { Modal, ModalTransition, useModal } from 'react-simple-hook-modal';
import DetailProduit from './DetailProduit';
import 'react-simple-hook-modal/dist/styles.css';
import { AiOutlineClose } from 'react-icons/ai';
import { getUniqueProduct } from '../selectors/catalogue';



const ProductGallery = ({ produitCatalogue, getProduitCatalogue, typeProduit, search, ligne, produitCatalogueUnique, forme }) => {

    const [ref, setRef] = useState('')
    const { isModalOpen, openModal, closeModal } = useModal();

    useEffect(() => {
        getProduitCatalogue()
    }, [getProduitCatalogue]);
    // useEffect(() => {
    //     window.addEventListener('keydown', e => {
    //         console.log(e.key);
    //     })
    // }, []);
    const showDetail = prod => {
        setRef(prod.ref)
        openModal()
    }
    const filterProd = () => {
        return Object.values(produitCatalogueUnique)
            .filter(f => search !== '' || typeProduit === '' || f.id_typ_prod === typeProduit)
            .filter(p => search === '' || p.ref.indexOf(search) > -1 || p.prenom_ligne.toUpperCase().indexOf(search.toUpperCase()) > -1)
            .filter(r => search !== '' || ligne === '' || r.prenom_ligne.toUpperCase().indexOf(ligne.toUpperCase()) > -1 || r.nom_ligne.toUpperCase().indexOf(ligne.toUpperCase()) > -1)
            .filter(f => search !== '' || forme === '' || f.formes.indexOf(forme) > -1)

    }
    const refresh = () => {
        getProduitCatalogue()
    }
    return (
        <>
            <Modal modalClassName="modalCustom" id="1" isOpen={isModalOpen} transition={ModalTransition.SCALE} onBackdropClick={closeModal}>
                <div className="">
                    <AiOutlineClose className="closeCard" onClick={() => closeModal()} />
                </div>
                <DetailProduit prod={ref} />
            </Modal>
            {/* <button onClick={() => console.log(produitCatalogueUnique)}>Test</button> */}
            <div className='row catalogue'>
                {filterProd()
                    .map((p) => (
                        <div key={p.ref + ' ' + p.label} className="col-sm">
                            <CardProduct
                                refresh={refresh}
                                detail={showDetail}
                                key={p.ref + ' ' + p.label}
                                prod={p}
                            />
                        </div>
                    ))}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        produitCatalogue: state.catalogue.produitCatalogue,
        produitCatalogueUnique: getUniqueProduct(state),
        typeProduit: state.catalogue.typeProduit,
        search: state.catalogue.search,
        ligne: state.catalogue.ligne,
        forme: state.catalogue.forme,

    }
}
const mapDispatchToProps = {
    getProduitCatalogue
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductGallery)