import React from 'react';
import { Modal } from 'antd'; // Utilisez le composant Modal de Ant Design
//import 'antd/dist/antd.css'; // Importez le CSS d'Ant Design

const ModalProduit = ({ isOpen, closeModal, img, imagesRepo }) => (
    <Modal
        width={'100 %'}
        open={isOpen}
        onCancel={closeModal}
        footer={null}
        centered
        className="luxurious-modal"
    >
        <img style={{ height: '600px' }} src={imagesRepo + img} onClick={closeModal} alt="" />
    </Modal>
);

export default ModalProduit;
