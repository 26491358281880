import { GET_PRODUIT_CATALOGUE, GET_USER, SET_AUTHENTIFICATION, SET_FORME_PIERRE, SET_LIGNE_PRODUIT_CATALOGUE, SET_SEARCH_PRODUIT_CATALOGUE, SET_TYPE_PRODUIT_CATALOGUE } from './action-types';
import axios from 'axios'

import { apiATC, meekroDB } from '../config'

export function setAuthentification(isLoggedIn) {
    return {
        type: SET_AUTHENTIFICATION,
        payload: isLoggedIn
    };
}
export function getProduitCatalogue() {
    return function (dispatch) {
        axios.get(`${meekroDB}/images/imageCatalogue.php`)
            .then(response => {
                dispatch({ type: GET_PRODUIT_CATALOGUE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function setTypeProduitCatalogue(type) {
    return {
        type: SET_TYPE_PRODUIT_CATALOGUE,
        payload: type
    };
}
export function setSearchProduitCatalogue(search) {
    return {
        type: SET_SEARCH_PRODUIT_CATALOGUE,
        payload: search
    };
}
export function setLigneProduitCatalogue(ligne) {
    return {
        type: SET_LIGNE_PRODUIT_CATALOGUE,
        payload: ligne
    };
}
export function setFormePierre(forme) {
    return {
        type: SET_FORME_PIERRE,
        payload: forme
    };
}
export function setLogin(user, password) {
    return function (dispatch) {
        const data = {
            user: user.toLowerCase(),
            password: password.toLowerCase()
        }
        return axios.post(`${apiATC}/user/login.php?action=login`, data)
            .then(response => {
                if (response.data.length === 0) {
                    dispatch({ type: GET_USER, payload: -1 });
                    return false;
                }
                else {
                    console.log(response.data[0].id_user);
                    if (response.data[0].id_user !== 0) {
                        const data = {
                            id_user: response.data[0].id_user,
                        }
                        axios.post(`${apiATC}/user/login.php?action=log`, data)
                            .catch(error => {
                                console.log(error);
                            });
                    }
                    localStorage.setItem('user', JSON.stringify(response.data))
                    dispatch({ type: GET_USER, payload: response.data });
                    return true;
                }

            })
            .catch(error => {
                console.log(error);
            });
    };
}
