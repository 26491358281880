import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { meekroDB } from '../config';
// import { withRouter } from "react-router-dom";

export default function RequireAuthentication(Component) {
    class RequireAuthentication extends React.Component {

        componentDidMount() {
            if (!this.props.isLoggedIn && !localStorage.getItem('user')) {
                this.props.history.push("/login");
            }
            else {
                var userString = localStorage.getItem('user');
                var userObj = JSON.parse(userString);
                var idUser = userObj[0].id_user;
                if (idUser !== 0) {
                    const data = {
                        id_user: idUser,
                    }
                    axios.post(`${meekroDB}/user/log.php`, data)
                        .catch(error => {
                            console.log(error);
                        });
                }
            }
        }
        // static getDerivedStateFromProps(nextProps) {

        //     if (!nextProps.isLoggedIn) {
        //         nextProps.history.push("/");
        //     }
        //     return { isLoggedIn: nextProps.isLoggedIn };
        // }


        render() {
            return <Component {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        isLoggedIn: state.authentification.isLoggedIn
    });


    return connect(mapStateToProps)(RequireAuthentication);
}