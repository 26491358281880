import React from 'react';
import { GiRing, GiPowerRing, GiHeartNecklace, GiPearlNecklace, GiHeartEarrings, GiDiamondRing } from 'react-icons/gi';

export const SidebarData = [
  // {
  //   title: 'Reset',
  //   id: '',
  //   icon: <CgIcons.CgRing />,
  //   cName: 'nav-text'
  // },
  {
    title: '1/2 Alliance',
    id: '9',
    icon: <GiRing />,
    cName: 'nav-text'
  },
  {
    title: 'Alliance',
    id: '6',
    icon: <GiRing />,
    cName: 'nav-text'
  },
  {
    title: 'Solitaire',
    id: '8',
    icon: <GiDiamondRing />,
    cName: 'nav-text'
  },

  {
    title: 'Bague',
    id: '1',
    icon: <GiPowerRing />,
    cName: 'nav-text'
  },
  {
    title: 'Pendentif',
    id: '2',
    icon: <GiHeartNecklace />,
    cName: 'nav-text'
  },
  {
    title: 'Collier',
    id: '7',
    icon: <GiPearlNecklace />,
    cName: 'nav-text'
  },
  {
    title: "Boucles d'Oreilles",
    id: '3',
    icon: <GiHeartEarrings />,
    cName: 'nav-text'
  }
  ,
  {
    title: "Bracelet",
    id: '4',
    icon: <GiRing />,
    cName: 'nav-text'
  }
];
