export const GET_PRODUIT_CATALOGUE = 'GET_PRODUIT_CATALOGUE';
export const SET_TYPE_PRODUIT_CATALOGUE = 'SET_TYPE_PRODUIT_CATALOGUE';
export const SET_SEARCH_PRODUIT_CATALOGUE = 'SET_SEARCH_PRODUIT_CATALOGUE';
export const SET_LIGNE_PRODUIT_CATALOGUE = 'SET_LIGNE_PRODUIT_CATALOGUE';
export const SET_FORME_PIERRE = 'SET_FORME_PIERRE';
export const SET_AUTHENTIFICATION = 'SET_AUTHENTIFICATION';
export const GET_USER = 'GET_USER';




