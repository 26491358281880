// import { saveAs } from 'file-saver'
import axios from 'axios'
import React from 'react'
import { meekroDB } from '../config'
const CardProduct = ({ prod, detail, refresh, css = 'card' }) => {
    const verifyImage = image => {
        try {
            //return (`https://extensia-france.com/imgs/${image}`)
            //return (`https://extensia-france.com/imgs/thumbs.php?src=${image}&size=250`)
            return (`https://extensia-france.com/imgs/thumb2023.php?image=${image}&size=250`)
        }
        catch (error) {
            // return (`https://extensia-france.com/imgs/thumbs.php?src=no_pic.jpg&size=250`)
            return (`https://extensia-france.com/imgs/thumb2023.php?image=no_pic.jpg&size=250`)

        }
    }
    const printTarif = (produit) => {
        const user = JSON.parse(localStorage.getItem('user'))[0];
        // console.log(user);
        window.open(`https://www.extensia-france.com/printers/impressionTarifClient.php?image=${produit.img}&idp=${produit.id_cv}&group=${user.groupe}&user=${user.id_user}&agence=${user.Num_agence}&admin=${user.admin}`, "_blank")
    }

    // const download = (file) => {

    //     const blob = new Blob([<img src={`https://extensia-france.com/imgs/${file}`} alt="" />], { type: "image/*" });
    //     saveAs(`https://extensia-france.com/imgs/${file}`, file);
    // }

    const supr = (prod) => {

        const data = {
            table: "centre_vide",
            photo_ok: "0",
            id: prod.id_cv,
            primaryKey: "id_cv"
        }
        axios.put(`${meekroDB}/general.php`, data)
            .then(response => {
                console.log(response);
                refresh();
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <div className={`card ${css}`} >
            <img src={verifyImage(prod.img)} alt={prod.img} onClick={() => detail(prod)} className="img-fluid" />
            {/* <hr /> */}
            <div className="footerCard" >
                <p>{prod.ref}</p>
                <p>{prod.prenom_ligne || '-'}</p>
                <p> <button onClick={() => printTarif(prod)} className="btn-sm btn-link" style={{ color: '#494f54' }}>Tarifs</button></p>
                {
                    localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))[0].id_user === 150 &&
                    <p> <button onClick={() => supr(prod)} className="btn-sm btn-link" >Désactiver</button></p>
                }
                {/* <p> <button onClick={() => supr(prod)} className="btn-sm btn-link" >Désactiver</button></p> */}
                <h5>{prod.description}</h5>
            </div>
        </div>



    )
}

export default (CardProduct)